<template>
  <div id="resourcePool">
    <div class="cm-container">
      <div class="resourcePool-title">BIM软件</div>
      <div class="cm-img">
        <div class="cm-img-list" v-for="(item, index) in lsitdata" :key="index" >
          <img :src="item.imgurl" alt="" />
          <div>{{item.name}}</div>
        </div>
      </div>
      <!-- <div class="cm-footer">
        <el-pagination
          background
          layout="prev, pager, next"
          prev-text="上一页"
          next-text="下一页"
          :total="300"
        >
        </el-pagination>
      </div> -->
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted, onUnmounted } from "vue";
// import router from "../../router/index.js";
export default {
  setup() {

    const state = reactive({
      lsitdata:[
        { name: "Revite", imgurl:require('../../../assets/img/rp/rp-1.png'),id: 1 },
        { name: "新点软件", imgurl:require('../../../assets/img/rp/rp-2.png'),id: 2 },
        { name: "广联达", imgurl:require('../../../assets/img/rp/rp-3.png'),id: 3 },
        { name: "中望CAD", imgurl:require('../../../assets/img/rp/rp-4.png'),id: 4 },
        { name: "盈建科", imgurl:require('../../../assets/img/rp/rp-5.png'),id: 5 },


      
      ],
      major: [
        { name: "全部", id: 1 },
        { name: "建筑工程", id: 2 },
        { name: "道桥工程", id: 3 },
        { name: "地下工程", id: 4 },
      ],
      majoractive: 1,
      jobs: [
        { name: "全部", id: 1 },
        { name: "施工员（测量员）", id: 2 },
        { name: "质量员", id: 3 },
        { name: "安全员", id: 4 },
        { name: "标准员", id: 5 },
        { name: "材料员", id: 6 },
        { name: "劳务员（预算员）", id: 7 },
        { name: "资料员", id: 8 },
      ],
      jobsactive: 1,
    });
    const methods = {
     
      majorfn: (item) => {
        state.majoractive = item.id;
      },
      jobfn: (item) => {
        state.jobsactive = item.id;
      },
    };
    onMounted(() => {});
    onUnmounted(() => {});
    // provide('popfalse',state.popfalse)
    return {
      ...toRefs(state),
      ...methods,
    };
  },
  components: {},
};
</script>
<style lang="scss" scoped>
#resourcePool {
  height: 100%;
  position: relative;
  .cm-container {
    width: 1200px;
    margin: 0 auto;
    background: #f3f3f3;
    .resourcePool-title{
      font-size: 15px;
      font-weight: bold;
      color: #555555;
      margin-top: 20px;
      box-sizing: border-box;
    }
    .cm-img {
      display: flex;
      flex-wrap: wrap;
      margin-top: 19px;
      box-sizing: border-box;
      .cm-img-list {
        width: 68.5px;
        height: 101px;
        // background: #ffffff;
        // border: 1px solid #e2e2e2;
        margin-right: 25px;
        margin-bottom: 20px;
        box-sizing: border-box;
        img {
          width: 100%;
          height: 67%;
          display: inline-block;
        }
        div {
          height: 33%;
          margin: 4px 0 0 0px;
          box-sizing: border-box;
          font-size: 14px;
          color: #333333;
        }
        &:nth-child(13n) {
          margin-right: 0;
        }
      }
    }

    .cm-footer {
      display: flex;
      justify-content: center;
      &:deep(.el-pager) {
        .number {
          border-radius: 2px;
          border: 1px solid #e2e2e2;
          color: #999;
        }
        .active{
          background-color:rgba(10, 179, 255, 0.59);
          color: #3C3C3C;
        }
      }
      &:deep(.btn-prev),&:deep(.btn-next){
         border-radius: 2px;
          border: 1px solid #e2e2e2;
          padding-left: 10px;
          padding-right: 10px;
          box-sizing: border-box;
          color: #999;
      }
    }
  }
}
@media screen and (min-width: 1600px) {
  #resourcePool {
    .cm-container {
      width: 1400px;
      
      .cm-img {
        .cm-img-list {
          width: 80px;
          height: 119px;
          // background: #ffffff;
          // border: 1px solid #e2e2e2;
          margin-right: 30px;
          margin-bottom: 20px;
          box-sizing: border-box;
          &:nth-child(13n) {
            margin-right: 0;
          }
        }
      }
    }
  }
}
</style>
